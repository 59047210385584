import { createRouter, createWebHashHistory } from 'vue-router'
import Landing from '../views/Landing.vue'
import Register from '../views/Register.vue'
import Login from '../views/Login.vue'
import Dashboard from '../views/Dashboard.vue'
import Admin from '@/views/Admin.vue'
import FakturaSpecs from '@/views/FakturaSpecs.vue'
import Terms from '@/views/Terms.vue'

const adminPath = 'tC2KRjS4WlBVpRfJwTkx'
const routes = [
  {
    path: '/landing',
    name: 'landing',
    component: Landing
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/' + adminPath,
    name: 'admin',
    component: Admin
  },
  {
    path: '/fakturaspec/:id',
    name: 'fakturaspecs',
    component: FakturaSpecs
  },
  {
    path: '/terms',
    name: 'terms',
    component: Terms
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from) => {
  const canAccess = await canUserAccess(to)
  if (!canAccess) return { name: 'login' }
})

function isAuthenticated() {
  var token = localStorage.getItem('loginToken')
  return token != null
}

function isAuthenticatedForAdmin() {
  var token = localStorage.getItem('adminPass')
  return token != null
}

async function canUserAccess(route) {
  return isAuthenticated() || ["login", "register", "admin", "terms"].includes(route.name)
}

export default router
