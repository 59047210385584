<template>
  <div class="d-flex flex-column h-100">
    <Topbar @logout="signout" class="no-print" />
    <div class="container-lg">
      <div class="row">
        <main id="main">
          <div class="h2">Fakturaunderlag</div>
          <Spinner v-if="loading" :dark="true" :size="75"></Spinner>
          <a href="/" class="logo-end">
            <img src="@/assets/img/logo.png" alt="" width="240" />
          </a>
          <span style="clear: both"></span>
          <hr style="margin-top: 50px" />
          <div v-if="!isEmpty">
            <table>
              <tr>
                <td>
                  <strong class="me-5">Fakturaperiod:</strong>
                </td>
                <td>{{ report.fakturaperiod }}</td>
              </tr>
              <tr>
                <td>
                  <strong>Kundnr:</strong>
                </td>
                <td>{{ report.kundNr }}</td>
              </tr>
              <tr>
                <td>
                  <strong>KundNamn:</strong>
                </td>
                <td>{{ report.kundNamn }}</td>
              </tr>
              <tr>
                <td>
                  <strong>FakturaNr:</strong>
                </td>
                <td>{{ this.$route.params.id }}</td>
              </tr>
            </table>
            <hr />
            <div style="clear: right"></div>
            <div v-for="remittentReport in report.remittentReports">
              <div v-for="personReport in remittentReport.personReports">
                <div class="table-responsive">
                  <table class="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <th class="table-primary" v-for="col in columns">
                          {{ col.headerName }}
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr scope="col" v-for="row in personReport.fakturaSpecs" v-bind:key="row.key">
                        <td scope="col" v-for="col in columns" v-bind:key="col?.field" :class="col.field">
                          {{ col != null && col.field in row ? row[col.field] : '' }}
                        </td>
                      </tr>
                      <tr>
                        <td :colspan="`${columns.length - 2}`">Remittent: {{ remittentReport.remittentId }}
                        </td>
                        <td>Summa patient {{ personReport.personNumber }}</td>
                        <td class="nettobelopp">{{ personReport.summaPerson }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <table class="table table-bordered table-sm sum">
                <tbody>
                  <tr>
                    <td class="produktnamn">Summa {{ remittentReport.remittentId }}</td>
                    <td class="nettobelopp">{{ remittentReport.summaRemittent}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <table class="table table-bordered table-sm sum">
              <tbody>
                <tr>
                  <td class="produktnamn">Bruttobelopp</td>
                  <td class="nettobelopp">{{ report.bruttoBellop}}</td>
                </tr>
                <tr>
                  <td class="produktnamn">Summa Kund</td>
                  <td class="nettobelopp">{{ report.summaKund}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center" v-if="!loading && !error && isEmpty">
            <strong>
              Inga fakturaspecs hittades!
              <i class="bi bi-check-circle"></i>
            </strong>
          </div>
          <div class="text-center" v-if="error">
            <strong>
              Error loading the data, please contact the admins.
              <i class="bi bi-emoji-dizzy"></i>
            </strong>
          </div>
        </main>
          <Spinner v-if="generating" :dark="true" class="btn-spinner"></Spinner>
          <button v-if="!generating" class="btn btn-success btn-lg no-print" v-on:click="pdf()"><i class="bi bi-download"></i></button>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import Api from '@/services'
import Spinner from '../components/Spinner.vue'
import Button from '../components/Button.vue'

export default {
  name: 'Home',
  data() {
    return {
      ascending: false,
      sortColumn: '',
      filterColumn: '',
      filterCritria: '',
      report: {},
      columns: [
        { headerName: 'Fakturanr', field: 'fakturanr', sortable: true },
        { headerName: 'Lidnr', field: 'lidnr', sortable: true },
        { headerName: 'Personnr', field: 'personnr', sortable: true },
        { headerName: 'Provdatum', field: 'provdatum', sortable: true },
        { headerName: 'Produktkod', field: 'produktkod', sortable: true },
        { headerName: 'Produktnamn', field: 'produktnamn', sortable: true },
        { headerName: 'Belopp', field: 'nettobelopp', sortable: true }
      ],
      loading: true,
      generating: false,
      error: false
    }
  },
  computed: {
    rows() {
      if (this.filterColumn && this.filterCritria) {
        return this.allRows.filter((p) => p[this.filterColumn] == this.filterCritria)
      }
      return this.allRows
    },
    isEmpty() {
      return this.report.remittentReports == null || this.report.remittentReports.length == 0
    }
  },

  mounted() {
    this.start()
    this.$root.noFooterPrint(true)
  },
  methods: {
    signout() {
      localStorage.removeItem('loginToken')
      this.$router.push('/login')
    },

    setFilter(column, filter) {
      this.filterColumn = column
      this.filterCritria = filter
    },

    async pdf() {
      this.generating = true;
      await Api.downloadSpecsPdf(this.$route.params.id)
      this.generating = false;
    },

    async start() {
      this.loading = true
      this.error = false
      const response = await Api.fakturaSpecs(this.$route.params.id)

      if (response == null) {
        this.error = true
      } else {
        this.report = response.data
      }
      this.loading = false
    }
  },
  components: {
    Sidebar,
    Topbar,
    Footer,
    Icon,
    Spinner,
    Button
  }
}
</script>
<style lang="scss" scoped="true">
body {
  font-size: 0.875rem;
}

.btn {
  display: block;
  width: 75px;
  height: 75px;
  right: 75px;
  bottom: 75px;
  position: fixed;
  box-shadow: 2px 3px 4px 1px rgb(138, 138, 138);
}

.btn-spinner {
  background-color: white;
  border-color: rgb(138, 138, 138);
  border-radius: 37px;
  bottom: 75px !important;
  box-shadow: 2px 3px 4px 1px rgb(138, 138, 138);
  display: block;
  height: 75px;
  position: fixed;
  right: 75px !important;
  width: 75px;
}

.nettobelopp {
  text-align: end;
  width: 100px;
}

.produktnamn {
  width: 450px;
}

.spinner {
  background-color: white;
  bottom: 50%;
  display: block;
  height: 100px;
  position: fixed;
  right: 50%;
  width: 100px;
}
.sum {
  width: auto;
  margin-right: 0px;
  margin-left: auto;
}

.h2 {
  display: inline;
  margin-top: 250px;
}

.logo-end {
  margin: 5px 5px 10px 5px;
  float: right;
}
</style>
