<template>
  <div class="container col-xl-10 col-xxl-8 px-4 py-5">
    <a href="/">
      <img class="mb-4 logo" src="@/assets/img/logo.svg" alt="" width="240" />
    </a>
    <div class="row align-items-center g-lg-5 py-5">
      <div class="col-lg-7 text-center text-lg-start">
        <h1 class="display-6 fw-bold lh-1 mb-3">Välkommen till E-invoice</h1>
        <p class="col-lg-10 fs-6">
          Här kan ni som är kunder hos oss på Synlab&nbsp;Sverige ta emot fakturor och bilagor. Ange e-postadress och klicka på skicka så kontaktar vi
          er.
        </p>
      </div>
      <div class="col-md-10 mx-auto col-lg-5">
        <form v-if="!done" class="p-4 p-md-5 border rounded-3 bg-light needs-validation" @submit.prevent="onRegister()" novalidate>
          <div class="form-floating mb-3">
            <input
              type="email"
              pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
              class="form-control"
              id="email"
              placeholder="name@example.com"
              v-model.trim="email"
              required
            />
            <label for="email">e-postadress</label>
            <div class="invalid-feedback">
              {{strings.invalidItem(strings.email)}}
            </div>
          </div>
          <Button :loading="loading">{{strings.Send}}</Button>
          <div class="alert alert-danger mt-4" v-if="errorMessage">{{ errorMessage }}</div>
          <hr class="my-4" />
          <small class="text-muted">Fyll i den e-postadress som ni önskar använda för att koppla er till E-Invoice.</small>
        </form>
        <div v-if="done" class="p-4 p-md-5 border rounded-3 text-center">
          <p>Du är nu med i väntelistan!</p>
          <p>
            <icon icon="check-circle"></icon>
          </p>
        </div>
      </div>
    </div>
  </div>
  <Footer />

</template>

<script>
import Api from '@/services'
import strings from '@/strings'
import Button from '@/components/Button'
import Icon from '../components/Icon.vue'
import Footer from '../components/Footer.vue'
export default {
  components: { Button, Icon, Footer },
  data() {
    return {
      email: '',
      done: false,
      loading: false,
      errorMessage: '',
      strings: strings
    }
  },
  methods: {
    async onRegister() {
      this.errorMessage = false
      const form = document.querySelector('.needs-validation')
      if (form.checkValidity()) {
        this.loading = true
        const resp = await Api.addToWaitingListAsync(this.email)
        if (resp === null || !resp.ok) {
          if (resp?.status == 429){
            //quota full
            this.errorMessage = this.strings.MaxRetriesExceeded
          }
          else this.errorMessage = this.strings.UnknownErrorOcured
        } else {
          let data = await resp.json()
          if (data.success) {
            this.done = true
          } else {
            this.errorMessage = data.message
          }
        }
        this.loading = false
      }
      form.classList.add('was-validated')
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  background-color: #003765;
}
h1,
p {
  color: #fff;
}
a {
  color: white;
}
a:hover {
  color: dodgerblue;
}
</style>
