<template>
  <div class="spinner">
    <img v-if="dark" src="../assets/img/spinner-dark.svg" :style="{ width: size + 'px' }" />
    <img v-if="!dark" src="../assets/img/spinner.svg" :style="{ width: size + 'px' }" />
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: 32
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed:{
  }
}
</script>

<style scoped>
div {
  display: inline;
  text-align: center;
}
img {
  height: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
</style>
