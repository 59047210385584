<template>
  <main class="form-signin">
    <a href="/">
      <img class="mb-4 logo" src="@/assets/img/logo.svg" alt="" width="240" />
    </a>
    <RegistrationInfoForm @infoSubmit="infoSubmitted($event)" v-if="stage == 'info'"></RegistrationInfoForm>
    <BankIdRegister :info="info" v-if="stage == 'bankid1'"></BankIdRegister>
    <p class="text-center">
      <a href="/">Tillbaka till logga in</a>
    </p>
  </main>
</template>

<script>
import RegistrationInfoForm from '@/components/account/RegistrationInfoForm'
import BankIdRegister from '../components/account/BankIdRegister.vue'
import Footer from '../components/Footer.vue'
export default {
  data() {
    return {
      stage: 'info',
      info: {}
    }
  },
  components: { RegistrationInfoForm, BankIdRegister, Footer },
  methods: {
    infoSubmitted(info) {
      this.stage = 'bankid1'
      this.info = info
    }
  }
}
</script>

<style scoped>
main {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}
</style>
