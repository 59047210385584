<template>
  <div :class="{ 'border-top': wide, 'no-print': noPrint }" class="footer">
    <div class="container">
      <footer class="d-flex flex-wrap justify-content-between align-items-center py-3" :class="{ 'border-top': !wide }">
        <p class="col-md-4 mb-3 text-muted address">
          <strong>Adress</strong>
          <br />
          <a href="https://goo.gl/maps/sWEx5z5h3DcUfxAeA"> <Icon icon="geo-alt-fill" />SYNLAB Centrallaboratorium <br />Nytorpsvägen 28-32 </a>
        </p>

        <p class="col-md-4 mb-3 text-muted">
          <strong>Synlab&nbsp;Sverige&nbsp;AB</strong>
          <br />
          <Icon icon="envelope-fill" />
          E-post:
          <a href="mailto:ekonomi@synlab.se">ekonomi@synlab.se</a>
          <br />
          <Icon icon="phone-fill" /> Telefon: <a href="tel:+46812319100"> 08-123 191 00</a>
        </p>

        <p class="col-md-4 mb-3 text-muted">
          © 2022 SYNLAB&nbsp;Sverige&nbsp;AB
          <br />Alla rättigheter förbehålls.
        </p>
      </footer>
    </div>
  </div>
</template>

<script>
import Icon from './Icon.vue'
export default {
  name: 'Footer',
  props: {
    wide: {
      type: Boolean,
      default: false
    },
    noPrint: {
      type: Boolean,
      default: false
    }
  },
  mounted() {},
  components: { Icon }
}
</script>

<style lang="scss" scoped>
.address a {
  text-decoration: none;
}
.address a:hover {
  text-decoration: underline;
}
</style>
