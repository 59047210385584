class Keys {
    email = ""
    Invalid = ""
    Send = ""
    MaxRetriesExceeded = ""
    UnknownErrorOcured = ""
    Next = ""
    organizationNumber = ""
    requiredField = ""
    Password = ""
    Enter = ""
    Save = ""

    invalidItem = (item) => this.Invalid + ' ' + item;
}
function fill() {
    let keys = new Keys()
    keys.email = "e-postadress"
    keys.Invalid = "Ogiltig"
    keys.Send = "Skicka"
    keys.MaxRetriesExceeded = "Du har uppnått max antal försök"
    keys.UnknownErrorOcured = "Ett fel har uppstått!"
    keys.Next = "Nästa"
    keys.organizationNumber = "Orgnummer"
    keys.requiredField = "Obligatorisk fält"
    keys.Password = "Lösenord"
    keys.Enter = "Enter"
    keys.Save = "Spara"
    return keys
}

let strings = fill()
export default strings;