<template>
  <div class="sticky-footer">
    <router-view @authenticated="setAuthenticated" />
    <div class="push"></div>
  </div>

  <Footer :noPrint="hideFooterInPrint" />
</template>

<script>
import Footer from '@/components/Footer.vue'

export default {
  name: 'App',
  data() {
    return {
      hideFooterInPrint: false
    }
  },
  computed: {},
  mounted() {},
  components: {
    Footer
  },
  methods: {
    setAuthenticated(loginToken) {
      localStorage.setItem('loginToken', loginToken)
    },
    noFooterPrint(hide) {
      this.hideFooterInPrint = hide
    }
  }
}
</script>
<style lang="sass">
@use 'assets/style/style'
</style>
