const BankIdTransactionStatus = {
    NotStarted: 0,
    Started: 1,
    Completed: 2,
    Failed: 3
}

const InvoiceType = {
    EInvoice : 0,
    PdfInvoice : 1,
    PaperInvoice : 2
}

export {BankIdTransactionStatus, InvoiceType}