<template>
  <a href="/">
    <img class="mb-4 logo" src="@/assets/img/logo.svg" alt="" width="240" />
  </a>
  <div class="container">
    <h2 class="text-center">Användarvillkor</h2>
    <h4>§ 1. INLEDNING</h4>
    <p>
      Dessa användarvillkor gäller för <strong>Einvoice.synlab.se</strong>. Läs noga igenom samtliga villkor innan du går vidare. Genom att acceptera
      dessa villkor <strong>godkänner du till att ingå ett juridiskt bindande avtal</strong> med Synlab Sverige AB med organisationsnummer
      559179-2881.
    </p>
    <p>
      Om du inte accepterar våra villkor ska godkännande inte lämnas, webbplatsen och dess tjänster kommer vid en sådan situation inte kunna användas.
    </p>
    <p>Önskas ingånget <strong> avtal sägas upp </strong> kan det göras på följande sätt:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;Avsluta konto</p>
    <p>Nedan angivna villkor kan komma att <strong>ändras</strong>. Innan eventuella ändringar träder i kraft meddelas användarna enligt följande:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp; Ändringar kommer annonseras på denna webplats</p>

    <h4>§ 2. SYFTE</h4>
    <p>Syftet med webbplatsen är följande:</p>
    <p>&nbsp;&nbsp;&nbsp;&nbsp;Syftet med denna webplats är att ge kunder tillgång till digitalt material avseende fakturahantering</p>

    <h4>§ 3. EGENDOMSRÄTT TILL WEBBPLATSEN</h4>
    <p>
      Webbplatsen <strong>ägs</strong> samt <strong>hanteras</strong> av <strong>Synlab Sverige AB</strong> i egenskap av juridisk person med säte i
      Stockholm.
    </p>

    <h4>§ 4. ANVÄNDARVILLKOR</h4>
    <p>
      <strong>a)</strong> Det är ej tillåtet att dela innehåll som kan uppfattas som; kränkande, hotfullt, diskriminerande samt av pornografisk natur.
    </p>
    <p><strong>b) </strong>Användarnas ytterligare <strong>skyldigheter:</strong></p>
    <p>- anmäla felaktigheter och/eller otillåten access till Synlab Sverige AB. Anmälan görs till medilab.ekonomi@synlab.se</p>
    <p>- ansvara för att på egen hand granska, ta del av och ladda ner de underlag och den information som genereras via E-Invoice</p>

    <h4>§ 5. IMMATERIALRÄTT</h4>
    <p>
      Det upphovsrättsligt skyddade material som finns tillgängligt på webbplatsen
      <strong>får inte användas utan tillstånd från upphovsmannen. </strong>Användarna tillåts således inte kopiera, distribuera, kommersiellt
      exploatera, reproducera eller på annat sätt dra nytta från sådant material.
    </p>

    <h4>§ 6. PERSONUPPGIFTSBEHANDLING</h4>
    <p>
      <strong>a)</strong> Synlab Sverige AB är personuppgiftsansvarig och ska därmed säkerställa att webbplatsen är uppförd i enlighet med
      Dataskyddsförordningen (GDPR).
    </p>
    <strong>b) Personuppgifter som kommer/kan komma att behandlas</strong>
    <p>Genom att acceptera dessa användarvillkor godkänner användaren behandling av personuppgifter enligt följande:</p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp; De personuppgifter som erfordras för inloggning och verifiering. Namn, Mejl, Personnummer för
      Bankid
    </p>
    <p>Känsliga personuppgifter tillåts inte samlas in och behandlas.</p>
    <strong>c) Hur personuppgifterna kommer behandlas</strong> 
    <p>Personuppgifterna kommer behandlas till följande syften:</p>
    <p>
      &nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp; De personuppgifter som erfordras för inloggning och verifiering. Namn, Mejl, Personnummer för
      Bankid
    </p>
    <p><strong>d) Tredje man</strong></p>
    <p>Användarnas personuppgifter kommer inte lämnas ut till tredje man, om det inte krävs p.g.a. Svensk lag eller order från domstol.</p>
    <p><strong>e)</strong> Period som personuppgifterna kommer sparas</p>
    <p>Personuppgifterna får endast sparas så länge det behövs för ändamålet och som längst i sju år, i enlighet med Bokföringslagen.</p>

    <p>
      <strong>f) Kryptering </strong>
    </p>
    <p>
      Webbplatsen har ett SSL-certifikat (Secure Socket Layer) som säkerställer att användarnas personuppgifter överförs på ett säkert och
      konfidentiellt sätt genom att informationen krypteras.
    </p>

    <p>
      <strong>g) Användarnas rättigheter </strong>
    </p>
    <p>Behandlas personuppgifterna har användarna rätt att få tillgång till dem.</p>
    <p>Registerutdrag begärs på begäran via: <a href="mailto:medilab.ITsupport@synlab.se">medilab.ITsupport@synlab.se</a></p>
    <p>Registerutdraget lämnas ut digitalt.</p>

    <h6><i>Rätt till begränsning av behandling</i></h6>
    <p>
      I vissa fall kan en person, vars personuppgifter det gäller, kräva att behandlingen av dessa ska begränsas. Så är fallet, t.ex. när personen i
      fråga redan begärt rättelse p.g.a. felaktiga uppgifter. Under tiden för detta kan då även behandlingen av de felaktiga uppgifterna begränsas.
    </p>

    <h6>
      <i>Dataportabilitet </i>
    </h6>
    <p>
      I vissa fall har en person som lämnat ut sina personuppgifter en rätt att få dessa flyttade till annan, exempelvis medietjänst. Den som först
      tagit emot personuppgifterna ska då underlätta en sådan överflyttning. Rätt att göra invändningar I vissa fall har en person rätt att göra
      invändningar mot hur hens personuppgifter behandlas. Detta gäller när personuppgifterna behandlas för att utföra en uppgift av allmänt intresse,
      som ett led i myndighetsutövning eller efter en intresseavvägning.
    </p>
    <h6><i>Rätt till radering</i></h6>
    <p>
      Användarna har en rätt att få sina personuppgifter raderade om så önskas. Det finns undantag då denna rättighet inskränks men i följande fall
      måste uppgifterna raderas:
    </p>
    <p>- Om uppgifterna inte längre behövs för de ändamål som de samlats in för,</p>
    <p>- Om samtycket återkallas, - Om personuppgifterna används för direktmarknadsföring och användaren motsätter sig det,</p>
    <p>- Om de inte finns berättigade skäl som väger tyngre än den enskildes intresse att radera informationen,</p>
    <p>- Om personuppgifterna har behandlats olagligt,</p>
    <p>- Om radering krävs för att uppfylla en rättslig skyldighet,</p>
    <p>
      Önskar en användare få sina personuppgifter raderade ska hen kontakta <a href="mailto:medilab.ekonomi@synlab.se">medilab.ekonomi@synlab.se</a>
    </p>

    <h4>§ 7. ANVÄNDARES AVTALSBROTT</h4>
    <p>En användare som bryter mot vad som framgår av dessa användarvillkor hanteras enligt följande:</p>
    <p>Användaren blir avstängd från sajten under 2 veckor om avtalsbrottet är av mindre art och permanent om det är ett grovt avtalsbrott</p>

    <h4>§ 8. FRISKRIVNING & ANSVARSBEGRÄNSNING</h4>
    <p>Synlab Sverige AB som äger webbplatsen, Einvoice.synlab.se, friskriver sig från följande juridiska ansvar:</p>
    <p>
      - Part är ej skyldig att fullgöra åtagande om dess fullgörande förhindras till följd av arbetskonflikt, krig, mobilisering eller
      militärinkallelser av motsvarande omfattning, förfogande förbud, beslag, uppror, upplopp, omfattande driftstörningar hos part eller dennes
      underleverantör som vid ingående part ej borde förutse eller annan situation och händelse som ska skulle kunna fall inom begreppet force
      majeure.
    </p>

    <h4>§ 9. JURISDIKTION</h4>
    <p>Webbplatsen, Einvoice.synlab.se, drivs från Sverige och användarvillkoren styrs av svensk lag.</p>

    <h4>§ 10. KONTAKT</h4>
    <p>
      Vid frågor som rör webbplatsen och dess innehåll nås vi via e-post <a href="mailto:medilab.ekonomi@synlab.se">medilab.ekonomi@synlab.se</a>.
    </p>
    <p>
      För frågor gällande behandling av personuppgifter ska vårt dataskyddsombud kontaktas via
      <a href="mailto:medilab.ITsupport@synlab.se">medilab.ITsupport@synlab.se</a>
    </p>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped></style>
