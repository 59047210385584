<template>
  <div>
    <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
      <span class="navbar-brand col-md-3 col-lg-2 me-0 px-3">Synlab Admin Dashboard</span>
      <div class="navbar-nav">
        <div class="nav-item text-nowrap">
          <a v-if="isAuthenticated" class="nav-link px-3" href="#" @click="signout">Logga ut</a>
        </div>
      </div>
    </header>
    <div class="container-fluid">
      <a href="/">
        <img class="mb-4 logo" src="@/assets/img/logo.svg" alt="" width="240" />
      </a>

      <main v-if="isAuthenticated">
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
          <h1 class="h2">Kunder</h1>
          <div class="form-floating">
            <input type="text" class="form-control" id="searchinput" placeholder="Sök" v-model.trim="searchText" />
            <label for="enhetnamn">Sök</label>
          </div>
        </div>
        <div class="table-responsive">
          <table v-if="rows && rows.length > 0" class="table table-striped table-sm">
            <thead>
              <tr>
                <th>Mottagningens/Vårdgivarens namn</th>
                <th>Kombikakod</th>
                <th>Kundnummer</th>
                <th>Organisationsnummer</th>
                <th>E-postadress</th>
                <th>Namn</th>
                <th>Personnummer</th>
                <th>Registreringsdatum</th>
                <th>Status</th>
                <th>Fakturaunderlag</th>
                <th>Hantera</th>
              </tr>
            </thead>
            <tbody>
              <tr scope="col" v-for="row in rows" :key="row.id">
                <td>{{ row.enhetName }}</td>
                <td>{{ row.kombikakod }}</td>
                <td>{{ row.kundNumber }}</td>
                <td>{{ row.orgnummer }}</td>
                <td>{{ row.email }}</td>
                <td>{{ row.firstName }} {{ row.lastName }}</td>
                <td>{{ row.personnummer }}</td>
                <td>{{ row.created }}</td>
                <td>{{ row.status }}</td>
                <td>{{ svenskaInvoiceType(row.preferedInvoiceType) }}</td>
                <td>
                  <span v-if="row.status == 'Unverified'">
                    <Button :fullWidth="false" :large="false" :loading="loading" @click="takeAction('verify', row.id)" btnType="btn-success">
                      Godkänna
                      <icon icon="check-circle"></icon> </Button
                    >&nbsp;
                    <Button :fullWidth="false" :large="false" :loading="loading" @click="takeAction('reject', row.id)">
                      Neka
                      <icon icon="x-circle"></icon>
                    </Button>
                  </span>
                  <Button v-if="row.status == 'Verified'" :fullWidth="false" :large="false" @click="takeAction('block', row.id)" :loading="loading">
                    Block
                    <icon icon="x-circle"></icon>
                  </Button>
                  <Button v-if="row.status == 'Blocked'" :fullWidth="false" :large="false" @click="takeAction('unblock', row.id)" :loading="loading">
                    Unblock
                    <icon icon="check-circle"></icon>
                  </Button>
                  <Button
                    v-if="row.status == 'Rejected'"
                    :fullWidth="false"
                    :large="false"
                    @click="takeAction('verify', row.id)"
                    :loading="loading"
                    btnType="btn-success"
                  >
                    Godkänna
                    <icon icon="check-circle"></icon>
                  </Button>
                  &nbsp;
                  <Button :fullWidth="false" :large="false" @click="takeAction('edit', row)" :loading="loading">
                    <icon icon="pencil-square"></icon>
                  </Button>
                  &nbsp;
                  <Button :fullWidth="false" :large="false" :loading="loading" btnType="btn-danger" @click="takeAction('delete', row)">
                    <icon icon="trash3"></icon>
                  </Button>
                </td>
              </tr>
            </tbody>
          </table>
          <p v-if="!rows || rows.length == 0">Hittade inga kunder.</p>
          <div class="modal fade" id="editModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Redigera {{ editingUser.firstName }} {{ editingUser.lastName }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                  <form class="g-3 needs-validation" @submit.prevent="onSave()" novalidate>
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="enhetnamn"
                        placeholder="Mottagningens/Enhetens namn"
                        v-model.trim="editingUser.enhetName"
                        required
                      />
                      <label for="enhetnamn">Mottagningens/Vårdgivarens namn</label>
                      <div class="invalid-feedback">{{ strings.requiredField }}</div>
                    </div>
                    <div class="form-floating">
                      <input type="text" class="form-control" id="kombikakod" placeholder="kombikakod" v-model.trim="editingUser.kombikakod" />
                      <label for="kombikakod">Kombikakod</label>
                      <div class="invalid-feedback">{{ strings.requiredField }}</div>
                    </div>
                    <div class="form-floating">
                      <input type="text" class="form-control" id="kundnummer" placeholder="kundnummer" v-model.trim="editingUser.kundNumber" />
                      <label for="kundnummer">Kundnummer</label>
                      <div class="invalid-feedback">{{ strings.requiredField }}</div>
                    </div>
                    <div class="form-floating">
                      <input
                        type="text"
                        class="form-control"
                        id="orgnummer"
                        placeholder="Orgnummer"
                        v-model.trim="editingUser.orgnummer"
                        required
                        pattern="\d{6}-\d{4}"
                      />
                      <label for="orgnummer">{{ strings.organizationNumber }}</label>
                      <div class="invalid-feedback">{{ strings.invalidItem(strings.organizationNumber) }}</div>
                    </div>
                    <div class="form-floating">
                      <input
                        type="email"
                        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                        class="form-control"
                        id="email"
                        placeholder="name@example.com"
                        v-model.trim="editingUser.email"
                        required
                      />
                      <label for="email">{{ strings.email }}</label>
                      <div class="invalid-feedback">{{ strings.invalidItem(strings.email) }}</div>
                    </div>

                    <div class="form-floating mb-3">
                      <h5 class="text-center">Önskad faktura</h5>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="invoiceType"
                          id="efaktura"
                          value="EInvoice"
                          checked
                          v-model="editingUser.preferedInvoiceType"
                        />
                        <label class="form-check-label" for="efaktura">E-faktura</label>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="invoiceType"
                          id="pdffaktura"
                          value="PdfInvoice"
                          v-model="editingUser.preferedInvoiceType"
                        />
                        <label class="form-check-label" for="pdffaktura">PDF-faktura</label>
                      </div>
                      <div class="form-floating" v-if="editingUser.preferedInvoiceType == 'PdfInvoice'">
                        <input
                          type="email"
                          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                          class="form-control"
                          id="fakturaRecipientEmail"
                          placeholder="name@example.com"
                          v-model.trim="editingUser.fakturaRecipientEmail"
                          required
                        />
                        <label for="email">önskad e-postadress dit fakturan ska gå</label>
                        <div class="invalid-feedback">{{ strings.invalidItem(strings.email) }}</div>
                      </div>
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="invoiceType"
                          id="paperfaktura"
                          value="PaperInvoice"
                          v-model="editingUser.preferedInvoiceType"
                        />
                        <label class="form-check-label" for="paperfaktura">Pappersfaktura</label>
                      </div>
                    </div>
                    <div class="modal-footer">
                      <Button :loading="loading">{{ strings.Save }}</Button>
                    </div>

                    <div class="alert alert-danger mt-4" v-if="errorMessage">{{ errorMessage }}</div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div id="modal-delete">
            <div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="deleteModalLabel" aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-header">
                    <h5 class="modal-title">Är du säker att du vill radera avnvändaren?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Nej"></button>
                  </div>
                  <div class="modal-body">
                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal">Nej</button>
                    &nbsp;
                    <button type="button" class="btn btn-danger" @click="deleteUser(deletingUser)">Radera!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <main v-if="!isAuthenticated" class="col-md-10 mx-auto col-lg-5">
        <form class="p-4 p-md-5 needs-validation" @submit.prevent="start()" novalidate>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="pass" placeholder="name@example.com" v-model.trim="pass" required />
            <label for="pass">{{ strings.Password }}</label>
            <div class="invalid-feedback">{{ strings.invalidItem(strings.Password) }}</div>
          </div>
          <Button :loading="loading">Logga in</Button>
          <div class="alert alert-danger mt-4" v-if="errorMessage">{{ errorMessage }}</div>
        </form>
      </main>
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import Sidebar from '@/components/Sidebar.vue'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import Api from '@/services'
import Button from '../components/Button.vue'
import strings from '@/strings'
import { InvoiceType } from '@/services/Enums'
import 'bootstrap/dist/js/bootstrap.js'
import { Modal } from 'bootstrap/dist/js/bootstrap.js'

export default {
  name: 'Admin',
  data() {
    return {
      userList: [],
      pass: '',
      isAuthenticated: false,
      loading: false,
      errorMessage: '',
      strings: strings,
      editingUser: {},
      editModal: {},
      deletingUser: '',
      deleteModal: {},
      searchText: ''
    }
  },
  methods: {
    signout(event) {
      this.$router.push('/login')
      event.preventDefault()
      return false
    },

    async onSave() {
      const form = document.querySelector('.needs-validation')
      if (form.checkValidity()) {
        const fakturaRecipientEmail = this.editingUser.preferedInvoiceType == 'PdfInvoice' ? this.editingUser.fakturaRecipientEmail : null
        const invoiceType = InvoiceType[this.editingUser.preferedInvoiceType]
        this.errorMessage = null
        this.loading = true
        let response = await Api.updateUserAsync(
          this.pass,
          this.editingUser.id,
          this.editingUser.email,
          this.editingUser.orgnummer,
          this.editingUser.enhetName,
          this.editingUser.kundNumber,
          this.editingUser.kombikakod,
          fakturaRecipientEmail,
          invoiceType
        )

        if (response === null) {
          this.errorMessage = strings.UnknownErrorOcured
        } else if (!response.success) {
          this.errorMessage = response.message
        } else {
          for (let index = 0; index < this.userList.length; index++) {
            const element = this.userList[index]
            if (element.id === this.editingUser.id) {
              this.userList[index] = response.data
            }
          }
          this.editModal.hide()
        }
        this.loading = false
      }
      form.classList.add('was-validated')
    },

    svenskaInvoiceType(eng) {
      const dic = { PdfInvoice: 'PDF-faktura', Paperinvoice: 'Pappersfaktura', Einvoice: 'E-faktura' }
      return dic[eng]
    },

    async deleteUser(id) {
      this.loading = true
      let response = await Api.deleteUser(this.pass, id)
      if (response === null) {
        this.errorMessage = strings.UnknownErrorOcured
      } else if (!response.success) {
        this.errorMessage = response.message
      } else {
        if (response.data) {
          this.userList = this.userList.filter((item) => item.id != id)
        }
      }
      this.loading = false
    },

    async takeAction(action, id) {
      if (action == 'edit') {
        this.editingUser = {
          id: id.id,
          enhetName: id.enhetName,
          kombikakod: id.kombikakod,
          kundNumber: id.kundNumber,
          orgnummer: id.orgnummer,
          email: id.email,
          name: id.firstName + id.lastName,
          preferedInvoiceType: id.preferedInvoiceType,
          fakturaRecipientEmail: id.fakturaRecipientEmail
        }
        this.editModal = new Modal('#editModal')
        this.editModal.show()
        return
      }

      if (action == 'delete') {
        this.deletingUser = id.id
        this.deleteModal = new Modal('#deleteModal')
        this.deleteModal.show()
        return
      }

      let targetStates = { verify: 1, reject: 2, block: 3, unblock: 1 }
      let targetStatus = targetStates[action]

      this.errorMessage = false
      this.loading = true
      let response = await Api.updateStatusAsync(this.pass, id, targetStatus)
      if (response === null) {
        this.errorMessage = strings.UnknownErrorOcured
      } else if (!response.success) {
        this.errorMessage = response.message
      } else {
        for (let index = 0; index < this.userList.length; index++) {
          const element = this.userList[index]
          if (element.id === id) {
            this.userList[index] = response.data
          }
        }
      }
      this.loading = false
    },

    async start() {
      this.errorMessage = false
      const form = document.querySelector('.needs-validation')
      this.loading = true
      let response = await Api.userlistAsync(this.pass)
      if (response === null) {
        this.isAuthenticated = false
        this.errorMessage = strings.UnknownErrorOcured
      } else if (typeof response == 'string') {
        console.log(response)
        this.errorMessage = response
      } else if (!response.success) {
        this.errorMessage = response.message
      } else {
        this.userList = response.data
        this.userList = this.userList.sort(function(a,b) {
          return new Date(b.created) - new Date(a.created)
        })
        this.isAuthenticated = true
      }
      this.loading = false
    }
  },
  computed: {
    rows() {
      if (this.searchText) {
        var filtered = this.userList.filter((obj) => 
        {
          for (const [key, value] of Object.entries(obj)) {
                if (value != null && value.includes(this.searchText))
                    return true;
          }
          return false;
        })
        return filtered;
      }
      return this.userList
    }
  },
  components: {
    Table,
    Sidebar,
    Topbar,
    Footer,
    Icon,
    Button
  },
  mounted() {}
}
</script>
<style>
body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

#kundnummer,
#kombikakod,
#enhetnamn,
#orgnummer {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
</style>
