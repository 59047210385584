<template>
  <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#">Synlab Dashboard</a>
    <div class="navbar-nav">
      <div class="nav-item text-nowrap">
        <a class="nav-link px-3" href="#" @click="logout">Logga ut</a>
      </div>
    </div>
  </header>
</template>

<script>
import Icon from '@/components/Icon.vue'
export default {
  components: { Icon },
  name: 'Topbar',
  emits: ['logout'],
  methods: {
    logout(event) {
      this.$emit('logout')
      event.preventDefault()
      return false
    }
  }
}
</script>

<style>
/*
   * Navbar
   */
.navbar.bg-dark {
  background-color: #002060 !important;
}

.nav-link.px-3 {
  font-size: 1rem;
}

.navbar-brand {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  background-color: rgba(0, 0, 0, 0.25);
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.25);
}

.navbar .navbar-toggler {
  top: 0.25rem;
  right: 1rem;
}

.navbar .form-control {
  padding: 0.75rem 1rem;
  border-width: 0;
  border-radius: 0;
}
</style>
