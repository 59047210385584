<template>
  <i :class="'bi-' + icon"></i>
</template>

<script>

export default {
  props: {
    icon: String
  },
  mounted() {
  }
}
</script>

<style scoped></style>
