import router from "../router";

export default class Api {
  static baseUrl = process.env.VUE_APP_API_BASE_URL
  static addToWaitingListUrl = this.baseUrl + '/api/WaitingList';
  static async addToWaitingListAsync(email) {
    try {
      const responce = await fetch(this.addToWaitingListUrl, {
        method: 'POST',
        body: `"${email}"`,
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      return responce;
    } catch (x) {
      return null;
    }
  }
  
  static staticBankIdQrCodeUrl = this.baseUrl + '/api/user/qr-static'
  static async staticBankIdQrCodeAsync() {
    try {
      const responce = await fetch(this.staticBankIdQrCodeUrl, {
        method: 'GET',
      })
      return responce.json();
    } catch (x) {
      return null;
    }
  }

  static animBankIdQrCodeUrl = this.baseUrl + '/api/user/qr'
  static async animBankIdQrCodeAsync(qrRequestToken) {
    try {
      const responce = await fetch(this.animBankIdQrCodeUrl+"?qrRequestToken="+qrRequestToken, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      let result = responce.text()
      return result
    } catch (x) {
      return null;
    }
  }

  static bankIdAuthUrl = this.baseUrl + '/api/user/bankid-auth'
  static async bankIdAuth() {
    try {
      const responce = await fetch(this.bankIdAuthUrl, {
        method: 'GET',
      })
      return responce.json();
    } catch (x) {
      return null;
    }
  }


  static bankIdRegisterUrl = new URL(this.baseUrl + '/api/user/bankid-poll-register')
  static async bankIdRegisterAsync(email, orgnummer, enhetnamn, kundnummer, orderRef, autoStart, isMobile, isQr, invoiceType, kombikakod, fakturaRecipientEmail) {
    let requestBody = {
      "Email": email,
      "Orgnummer": orgnummer,
      "EnhetName": enhetnamn,
      "KundNumber": kundnummer,
      "OrderRef": orderRef,
      "AutoStart": autoStart,
      "IsQr": isQr,
      "IsMobile": isMobile,
      "PreferedInvoiceType": Number(invoiceType),
      "Kombikakod": kombikakod,
      "FakturaRecipientEmail": fakturaRecipientEmail
    }
    try {
      const responce = await fetch(this.bankIdRegisterUrl, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static bankIdLoginUrl = new URL(this.baseUrl + '/api/user/bankid-poll-login')
  static async bankIdLoginAsync(orderRef, autoStart, isMobile, isQr, kundNummer) {
    let requestBody = {
      "OrderRef": orderRef,
      "AutoStart": autoStart,
      "IsQr": isQr,
      "IsMobile": isMobile,
      "KundNummer": kundNummer
    }
    try {
      const responce = await fetch(this.bankIdLoginUrl, {
        method: 'POST',
        body: JSON.stringify(requestBody),
        headers: {
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static userListUrl = new URL(this.baseUrl + '/api/user/list')
  static async userlistAsync(pass) {
    try {
      const responce = await fetch(this.userListUrl, {
        method: 'GET',
        headers: {
          'token': pass
        },
        mode: 'cors'
      })
      if (responce.status== 401){
        return "Felaktigt lösenord angivet"
      }
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static updateStatusUrl = new URL(this.baseUrl + '/api/user/updatestatus')
  static async updateStatusAsync(pass, id, status) {
    let requestBody = {
      "id": id,
      "status": status
    }
    try {
      const responce = await fetch(this.updateStatusUrl, {
        method: 'POST',
        headers: {
          'token': pass,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        mode: 'cors'
      })
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static updateUserUrl = new URL(this.baseUrl + '/api/user/update')
  static async updateUserAsync(pass, id, email, orgnummer, enhetName, kundNumber, kombikakod, fakturaRecipientEmail, preferedInvoiceType) {
    let requestBody = {
      "id": id,
      "email": email,
      "orgnummer": orgnummer,
      "enhetName": enhetName,
      "kundNumber": kundNumber,
      "kombikakod": kombikakod,
      "fakturaRecipientEmail": fakturaRecipientEmail,
      "preferedInvoiceType": preferedInvoiceType,
    }
    try {
      const responce = await fetch(this.updateUserUrl, {
        method: 'POST',
        headers: {
          'token': pass,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
        mode: 'cors'
      })
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static deleteUserUrl = new URL(this.baseUrl + '/api/user/delete')
  static async deleteUser(pass, id) {
    try {
      const responce = await fetch(this.deleteUserUrl + "?id=" + id, {
        method: 'POST',
        headers: {
          'token': pass,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static fakturasUrl = new URL(this.baseUrl + '/api/faktura')
  static async fakturas() {
    let loginToken = localStorage.getItem("loginToken")
    if (!loginToken) {
      this.logout()
      return null;
    }
    try {
      const responce = await fetch(this.fakturasUrl, {
        headers: {
          'token': loginToken,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      if (responce.status == 401) {
        this.logout()
        return null;
      }
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static async downloadPdf(fakturaNr) {
    let download = require('./download.min');
    let downloadUrl = new URL(this.baseUrl + '/api/faktura/download' + "?fakturaNr=" + fakturaNr)
    let loginToken = localStorage.getItem("loginToken")
    if (!loginToken) {
      this.logout()
      return null;
    }
    try {
      const responce = await fetch(downloadUrl, {
        headers: {
          'token': loginToken,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      if (responce.status == 401) {
        this.logout()
        return null;
      }
      if(responce.status == 404){
        alert("Filen hittas inte!")
        return null
      }
      let blob = await responce.blob()
      download(blob, "invoice.pdf", "application/pdf");
    } catch (x) {
      return null;
    }
  }

  static async downloadExcel(fakturaNr) {
    let download = require('./download.min');
    let downloadUrl = new URL(this.baseUrl + '/api/faktura/excel' + "?fakturaNr=" + fakturaNr)
    let loginToken = localStorage.getItem("loginToken")
    if (!loginToken) {
      this.logout()
      return null;
    }
    try {
      const responce = await fetch(downloadUrl, {
        headers: {
          'token': loginToken,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      if (responce.status == 401) {
        this.logout()
        return null;
      }
      if(responce.status == 404){
        alert("Filen hittas inte!")
        return null
      }
      let blob = await responce.blob()
      download(blob, fakturaNr+".xlsx", "application/vnd.ms-excel");
    } catch (x) {
      return null;
    }
  }

  static async downloadSpecsPdf(fakturaNr) {
    let download = require('./download.min');
    let downloadUrl = new URL(this.baseUrl + '/api/faktura/specs-download' + "?fakturaNr=" + fakturaNr)
    let loginToken = localStorage.getItem("loginToken")
    if (!loginToken) {
      this.logout()
      return null;
    }
    try {
      const responce = await fetch(downloadUrl, {
        headers: {
          'token': loginToken,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      if (responce.status == 401) {
        this.logout()
        return null;
      }
      if(responce.status == 404){
        alert("Filen hittas inte!")
        return null
      }
      let blob = await responce.blob()
      download(blob, `${fakturaNr}-fakturaspec.pdf`, "application/pdf");
    } catch (x) {
      return null;
    }
  }

  static async fakturaSpecs(fakturaNr) {
    let fakturaSpecsUrl = new URL(this.baseUrl + '/api/faktura/specs' + "?fakturaNr=" + fakturaNr)
    let loginToken = localStorage.getItem("loginToken")
    if (!loginToken) {
      this.logout()
      return null;
    }
    try {
      const responce = await fetch(fakturaSpecsUrl, {
        headers: {
          'token': loginToken,
          'Content-Type': 'application/json'
        },
        mode: 'cors'
      })
      if (responce.status == 401) {
        this.logout()
        return null;
      }
      return await responce.json();
    } catch (x) {
      return null;
    }
  }

  static logout() {
    let loginToken = localStorage.removeItem("loginToken")
    router.replace({ path: '/login' })
  }
}
