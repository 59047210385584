<template>
  <div>
    <div class="table-responsive">
      <table class="table  table-striped table-sm">
        <thead>
          <tr>
            <th  class ="table-primary" v-for="col in columns" v-on:click="sortTable(col.field)">
              {{ col.headerName }}
              <i v-if="col.field == sortColumn && ascending" class="bi-arrow-up" />
              <i v-if="col.field == sortColumn && !ascending" class="bi-arrow-down" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr scope="col" v-for="row in rows" v-bind:key="row.key">
            <td
              scope="col"
              v-for="col in columns"
              v-bind:key="col?.field"
            >{{ col != null ? row[col.field] : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="false">
      <ul class="pagination">
        <li class="page-item disabled">
          <a class="page-link" href="#">&laquo;</a>
        </li>
        <li class="page-item active">
          <a class="page-link" href="#">1</a>
        </li>
        <li class="page-item">
          <a class="page-link" href="#">2</a>
        </li>
        <li class="page-item disabled">
          <a class="page-link" href="#">&raquo;</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'

export default {
  name: 'Table',
  data() {
    return {
      ascending: false,
      sortColumn: ''
    }
  },

  props: {
    rows: Array,
    columns: Array,
  },
  methods: {
    sortTable: function sortTable(col) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending;
      } else {
        this.ascending = true;
        this.sortColumn = col;
      }
      var ascending = this.ascending;

      this.rows.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0;
      })
    },
  },

  components: {
    Icon,
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
