<template>
  <div v-if="!success">
    <ol class>
      <li>Öppna BankID-appen i mobilen.</li>
      <li>Tryck på QR-symbolen i BankID-appen.</li>
      <li>Rikta kameran mot QR-koden i denna ruta.</li>
    </ol>
    <div class="alert alert-success mt-4" v-if="instruction">
      <spinner v-if="loading"></spinner>
      {{ instruction }}
    </div>
    <div class="alert alert-danger mt-4" v-if="error">
      {{ error }}
      <a href="#" @click="startaOm">Starta Om</a>
    </div>
    <div class="qr-container">
      <img v-if="qrImage && !error" v-bind:src="'data:image/png;base64,' + qrImage" />

      <img v-if="!qrImage" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
      <div class="qr-overlay" v-if="loadingQr">
        <spinner class="qr-loading"></spinner>
      </div>
      <a :href="sameDeviceUrl" class="text-center" v-if="qrImage">
        <p>Använd BankID på samma enhet</p>
      </a>
    </div>
  </div>
  <div v-if="success" class="mx-auto text-center">
    <h5 v-if="!successMessage" class="fw-light">Tack för din registrering, ditt konto kommer nu att granskas.</h5>
    <h5 v-if="successMessage" class="fw-light">{{ successMessage }}</h5>
    <p v-if="!successMessage" class="text-muted">Du som användare kommer att kunna logga in när kontot har verifierats av administratören.</p>
    <router-link to="login">Tillbaka</router-link>
  </div>
</template>

<script>
import Api from '@/services'
import strings from '@/strings'
import Spinner from '../Spinner.vue'
import { mobileAndTabletCheck } from '@/services/Utilities'
import { BankIdTransactionStatus } from '@/services/Enums'

export default {
  components: { Spinner },
  props: {
    info: Object
  },
  data() {
    return {
      error: '',
      status: '',
      instruction: '',
      loading: false,
      loadingQr: false,
      success: false,
      refreshIntervalId: 0,
      orderRef: '',
      qrImage: '',
      sameDeviceUrl: '',
      successMessage: '',
      qrRequestToken: ''
    }
  },
  methods: {
    fail(error) {
      this.error = error
      this.instruction = null
      this.loading = false
      this.success = false
    },
    complete() {
      this.success = true
    },
    instruct(msg) {
      this.instruction = msg
      this.error = null
      this.loading = true
      this.success = false
    },
    async poll() {
      if (this.orderRef) {
        var status = await Api.bankIdRegisterAsync(
          this.info.email,
          this.info.orgnummer,
          this.info.enhetnamn,
          this.info.kundnummer,
          this.orderRef,
          false,
          mobileAndTabletCheck(),
          true,
          this.info.invoiceType,
          this.info.kombikakod,
          this.info.fakturaRecipientEmail
        )
        if (status == null) {
          this.fail(strings.UnknownErrorOcured)
        } else if (!status.success) {
          this.fail(status.message)
        } else if (status.data == null) {
          this.successMessage = status.message
          this.complete()
        } else if (status.data.status == BankIdTransactionStatus.Failed) {
          this.fail(status.data.message)
        } else if (status.data.status == BankIdTransactionStatus.Completed) {
          this.complete()
        } else {
          this.instruct(status.data.message)
        }
      } else {
        this.loadingQr = true
      }
      await this.loadQrAnimated()
      this.loadingQr = false
      if (!this.error && !this.success) {
        setTimeout(
          async function () {
            await this.poll()
          }.bind(this),
          1000
        )
      }
    },

    async loadQrAnimated() {
      if (!this.qrRequestToken) {
        const response = await Api.bankIdAuth()
        if (response === null) {
          this.fail(strings.UnknownErrorOcured)
        } else {
          this.qrImage = response.base64Image
          this.orderRef = response.orderRef
          this.sameDeviceUrl = response.sameDeviceUrl
          this.qrRequestToken = response.qrRequestToken
        }
      } else {
        const response = await Api.animBankIdQrCodeAsync(this.qrRequestToken)
        if (!response) {
          this.fail(strings.UnknownErrorOcured)
        } else {
          this.qrImage = response
        }
      }
    },

    startaOm(event) {
      event.preventDefault()
      this.start()
      return false
    },
    async start() {
      this.error = ''
      this.qrRequestToken = ''
      this.orderRef = ''
      await this.poll()
    }
  },

  mounted() {
    this.start()
  }
}
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.qr-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.qr-container .qr-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.qr-spinner {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>
