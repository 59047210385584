<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
  >
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link class="nav-link active" to="/">
            <Icon icon="archive"/>
            Fakturor
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/bilagor">
            <Icon icon="file-text"/>
            Bilagor
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/orders">
            <Icon icon="file"/>
            Profil
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import Icon from './Icon.vue'
export default 
{
  components: { Icon },
  name: 'Sidebar',
  mounted() {
  }
}
</script>
<style>
/*
   * Sidebar
   */

.sidebar {
  position: relative;
  top: 0;
    right: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

@media (max-width: 767.98px) {
  .sidebar {
    top: 5rem;
  }
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #727272;
}

.sidebar .nav-link.active {
  color: #2470dc;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

</style>
