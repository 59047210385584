<template>
  <button
    id="submit-landing"
    :class="[
      {
        disabled: loading,
        'w-100': fullWidth,
        'btn-lg': large
      },
      'btn',
      btnType
    ]"
    type="submit"
  >
    <slot></slot>
    <spinner v-if="loading" :size="large ? null : 23"> </spinner>
  </button>
</template>

<script>
import Spinner from '@/components/Spinner'
export default {
  components: {
    Spinner
  },
  props: {
    loading: Boolean,
    fullWidth: {
      type: Boolean,
      default: true
    },
    large: {
      type: Boolean,
      default: true
    },
    btnType: { type: String, default: 'btn-primary' }
  },
  computed: {},
  data() {
    return {
    }
  }
}
</script>

<style></style>
