<template>
  <main class="form-signin" >
    <a href="/">
      <img class="mb-4 logo" src="@/assets/img/logo.svg" alt="" width="240" />
    </a>
    <h3 class="text-center">Inloggning</h3>

    <BankIdLogin v-if="stage == 'bankid'" @success="bankIdLoginSuccess"></BankIdLogin>
    <!-- <Button v-if="stage == 'ready'" @click="buttonClick">Logga in med mobilt BankID</Button> -->
    <Button v-if="stage == 'ready'" @click="buttonClick">Logga in med BankID</Button>
    <p v-if="stage == 'ready'" class="text-center">
      <router-link class="btn btn-primary w-100 btn-lg" to="/register">Skapa Konto</router-link>
    </p>
  </main>
</template>

<script>
import BankIdLogin from '../components/account/BankIdLogin.vue'
import Button from '../components/Button.vue'
import Footer from '../components/Footer.vue'
export default {
  data() {
    return {
      stage: 'ready'
    }
  },
  components: { BankIdLogin, Footer, Button },
  emits: ['authenticated'],
  methods: {
    buttonClick() {
      this.stage = 'bankid'
    },
    bankIdLoginSuccess(loginToken) {
      this.$emit('authenticated', loginToken)
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
main {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}
.btn{
  margin-top: 5px;
}
</style>
