<template>
  <form v-if="stage == 'input'" class="inputbox needs-validation" @submit.prevent="onNext()" novalidate>
    <div class="form-floating">
      <input required type="text" class="form-control" id="kundnummer" placeholder="kundnummer" v-model.trim="kundnummer" />
      <label for="kundnummer">Kundnummer</label>
      <div class="invalid-feedback">{{ strings.requiredField }}</div>
    </div>
    <Button>{{ strings.Next }}</Button>
  </form>

  <div v-if="stage == 'bankid'">
    <ol class="">
      <li>Öppna BankID-appen i mobilen.</li>
      <li>Tryck på QR-symbolen i BankID-appen.</li>
      <li>Rikta kameran mot QR-koden i denna ruta.</li>
    </ol>
    <div class="alert alert-success mt-4" v-if="instruction">
      <spinner v-if="loading"></spinner>
      {{ instruction }}
    </div>
    <div class="qr-container">
      <img v-if="qrImage" v-bind:src="'data:image/png;base64,' + qrImage" />

      <img v-if="!qrImage" src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7" />
      <div class="qr-overlay" v-if="loadingQr">
        <spinner class="qr-loading"></spinner>
      </div>
      <a :href="sameDeviceUrl" class="text-center" v-if="qrImage"><p>Använd BankID på samma enhet</p></a>
    </div>
  </div>
  <div v-if="stage == 'success'" class="mx-auto text-center">
    <h5 v-if="!successMessage" class="fw-light">Login done</h5>
    <h5 v-if="successMessage" class="fw-light">{{ successMessage }}</h5>
  </div>
  <div v-if="stage == 'error'" class="alert alert-danger mt-4">
    {{ error }}
    <a href="#" @click="startaOm">Starta Om</a>
  </div>
</template>

<script>
import Api from '@/services'
import strings from '@/strings'
import Spinner from '../Spinner.vue'
import Button from '../Button.vue'
import { mobileAndTabletCheck } from '@/services/Utilities'
import { BankIdTransactionStatus } from '@/services/Enums'

export default {
  components: { Spinner, Button },
  props: {},
  emits: ['success'],
  data() {
    return {
      stage: 'input',
      kundnummer: '',
      error: '',
      instruction: '',
      loading: false,
      loadingQr: false,
      success: false,
      refreshIntervalId: 0,
      orderRef: '',
      qrImage: '',
      sameDeviceUrl: '',
      successMessage: '',
      strings: strings,
      qrRequestToken: ''
    }
  },
  methods: {
    fail(error) {
      this.error = error
      this.instruction = null
      this.loading = false
      this.stage = 'error'
    },

    complete() {
      this.stage = 'success'
    },

    instruct(msg) {
      this.instruction = msg
      this.stage = 'bankid'
      this.loading = true
    },

    async poll() {
      if (this.orderRef) {
        var status = await Api.bankIdLoginAsync(this.orderRef, false, mobileAndTabletCheck(), true, this.kundnummer)
        if (status == null) {
          this.fail(strings.UnknownErrorOcured)
        } else if (!status.success) {
          this.fail(status.message)
        } else if (status.data == null) {
          this.fail(status.message)
        } else if (status.data.pollResult.status == BankIdTransactionStatus.Failed) {
          this.fail(status.data.pollResult.message)
        } else if (status.data.pollResult.status == BankIdTransactionStatus.Completed) {
          this.$emit('success', status.data.loginToken)
          this.complete()
        } else {
          this.instruct(status.data.pollResult.message)
        }
      } else {
        this.loadingQr = true
      }
      await this.loadQrAnimated()
      this.loadingQr = false
      if (this.stage == 'bankid') {
        setTimeout(
          async function () {
            await this.poll()
          }.bind(this),
          1000
        )
      }
    },

    async loadQrAnimated() {
      if (!this.qrRequestToken) {
        const response = await Api.bankIdAuth()
        if (response === null) {
          this.fail(strings.UnknownErrorOcured)
        } else {
          this.qrImage = response.base64Image
          this.orderRef = response.orderRef
          this.sameDeviceUrl = response.sameDeviceUrl
          this.qrRequestToken = response.qrRequestToken
        }
      } else {
        const response = await Api.animBankIdQrCodeAsync(this.qrRequestToken)
        if (!response) {
          this.fail(strings.UnknownErrorOcured)
        } else {
          this.qrImage = response
        }
      }
    },

    startaOm(event) {
      event.preventDefault()
      this.error = ''
      this.stage = 'input'
      this.orderRef = ''
      this.qrRequestToken = ''
      return false
    },

    onNext() {
      const form = document.querySelector('.needs-validation')
      if (form.checkValidity()) {
        this.start()
      }
      form.classList.add('was-validated')
    },

    async start() {
      this.error = ''
      this.stage = 'bankid'
      await this.poll()
    }
  },

  mounted() {}
}
</script>

<style scoped>
img {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
button {
  margin-top: 10px;
}
.inputbox {
  margin-top: 30px;
}
.qr-container {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.qr-container .qr-loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(0, 0, 0, 0.6);
  color: #fff;
}
.qr-spinner {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
</style>
