<template>
  <h3 class="text-center">Skapa konto</h3>
  <form class="g-3 needs-validation" @submit.prevent="onRegister()" novalidate>
    <div class="form-floating">
      <input type="text" class="form-control" id="enhetnamn" placeholder="Mottagningens/Enhetens namn" v-model.trim="enhetnamn" required />
      <label for="enhetnamn">Mottagningens/Vårdgivarens namn*</label>
      <div class="invalid-feedback">{{ strings.requiredField }}</div>
    </div>
    <div class="form-floating">
      <input type="text" class="form-control" id="kombikakod" placeholder="kombikakod" v-model.trim="kombikakod" />
      <label for="kombikakod">Kombikakod</label>
      <div class="invalid-feedback">{{ strings.requiredField }}</div>
    </div>
    <div class="form-floating">
      <input type="text" class="form-control" id="kundnummer" placeholder="kundnummer" v-model.trim="kundnummer" />
      <label for="kundnummer">Kundnummer</label>
      <div class="invalid-feedback">{{ strings.requiredField }}</div>
    </div>
    <div class="form-floating">
      <input type="text" class="form-control" id="orgnummer" placeholder="Orgnummer" v-model.trim="orgnummer" required pattern="\d{6}-\d{4}" />
      <label for="orgnummer">{{ strings.organizationNumber }}*</label>
      <div class="invalid-feedback">{{ strings.invalidItem(strings.organizationNumber) }}</div>
    </div>
    <div class="form-floating">
      <input
        type="email"
        pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
        class="form-control"
        id="email"
        placeholder="name@example.com"
        v-model.trim="email"
        required
      />
      <label for="email">{{ strings.email }}*</label>
      <div class="invalid-feedback">{{ strings.invalidItem(strings.email) }}</div>
    </div>

    <div class="form-floating mb-3">
      <h5 class="text-center">Önskad faktura</h5>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="invoiceType" id="efaktura" value="0" checked v-model="invoiceType" />
        <label class="form-check-label" for="efaktura">E-faktura</label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="invoiceType" id="pdffaktura" value="1" v-model="invoiceType" />
        <label class="form-check-label" for="pdffaktura">PDF-faktura</label>
      </div>
      <div class="form-floating" v-if="invoiceType == 1">
        <input
          type="email"
          pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
          class="form-control"
          id="fakturaRecipientEmail"
          placeholder="name@example.com"
          v-model.trim="fakturaRecipientEmail"
          required
        />
        <label for="email">e-postadress för fakturan</label>
        <div class="invalid-feedback">{{ strings.invalidItem(strings.email) }}</div>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="radio" name="invoiceType" id="paperfaktura" value="2" v-model="invoiceType" />
        <label class="form-check-label" for="paperfaktura">Pappersfaktura</label>
      </div>
    </div>
    <div class="form-check mb-3">
      <input class="form-check-input" type="checkbox" v-model="acceptTerms" id="accept-terms" required />
      <label class="form-check-label" for="accept-terms">
        Ja, jag godkänner SYNLABs E-Invoice
        <router-link to="/terms">användarvillkor.</router-link>*
      </label>
      <div class="invalid-feedback">Du måste godkänna villkoren innan du kan skapa kontot.</div>
    </div>
    <Button :loading="loading">{{ strings.Next }}</Button>
    <div class="alert alert-danger mt-4" v-if="errorMessage">{{ errorMessage }}</div>
  </form>
  <!-- <p class="mt-5 mb-3 text-muted text-center">
    Har redan konto?
    <router-link to="/login">logga in här</router-link>
  </p>-->
</template>

<script>
import strings from '@/strings'
import Button from '@/components/Button'
import Icon from '@/components/Icon.vue'
export default {
  components: { Button, Icon },
  data() {
    return {
      email: '',
      orgnummer: '',
      personnummer: '',
      enhetnamn: '',
      kundnummer: '',
      kombikakod: '',
      fakturaRecipientEmail: '',
      invoiceType: 0,
      acceptTerms: false,
      done: false,
      loading: false,
      errorMessage: '',
      strings: strings
    }
  },
  computed: {},
  emits: 'infoSubmit',
  methods: {
    async onRegister() {
      const form = document.querySelector('.needs-validation')
      if (form.checkValidity()) {
        this.$emit('infoSubmit', {
          email: this.email,
          orgnummer: this.orgnummer,
          enhetnamn: this.enhetnamn,
          kundnummer: this.kundnummer,
          invoiceType: this.invoiceType,
          kombikakod: this.kombikakod,
          fakturaRecipientEmail: this.fakturaRecipientEmail
        })
      }
      form.classList.add('was-validated')
    }
  }
}
</script>

<style lang="scss" scoped>
.form-floating:focus-within {
  z-index: 2;
}

#kundnummer,
#kombikakod,
#enhetnamn,
#orgnummer {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 50px;
}

#email {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.form-control:valid{
  border-color: rgba(0, 0, 0, 0.1);
  background-image: none;
}

.was-validated .form-check-input:valid ~ .form-check-label{
  color: black;
}
.was-validated .form-check-input:valid:checked{
border-color: #003765;
background-color: #003765;
}
.was-validated .form-check-input:valid{
border-color: rgba(0, 0, 0, 0.25)
}

</style>
