<template>
  <div>
    <Topbar @logout="signout" />
    <div class="container-fluid">
      <a href="/">
        <img class="mb-4 logo" src="@/assets/img/logo.svg" alt="" width="240" />
      </a>
      <h1 class="h2">
        Fakturor
        <Spinner v-if="loading" :dark="true"></Spinner>
      </h1>
      <div v-if="allRows.length > 0">
        <p><strong>Kundnr</strong> {{ allRows[0].kundNr }}</p>
        <p><strong>kundnamn</strong> {{ allRows[0].kundNamn }}</p>
        <div class="container-fluid">
          <div class="row p-1">
            <div class="col">
              <div class="border p-1">
                <h6>Status</h6>
                <button
                  class="btn btn-primary btn-sm"
                  :class="{ 'bg-secondary': filterCritria == 'Betald', 'bg-primary': filterCritria != 'Betald' }"
                  v-on:click="setFilter('status', 'Betald')"
                >
                  Betald
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  :class="{ 'bg-secondary': filterCritria == 'Förfallen', 'bg-primary': filterCritria != 'Förfallen' }"
                  v-on:click="setFilter('status', 'Förfallen')"
                >
                  Förfallen
                </button>
                <button
                  class="btn btn-primary btn-sm"
                  :class="{ 'bg-secondary': filterCritria == 'Obetald', 'bg-primary': filterCritria != 'Obetald' }"
                  v-on:click="setFilter('status', 'Obetald')"
                >
                  Obetald
                </button>
                &nbsp; <a href="#" v-if="filterColumn == 'status'" v-on:click="setFilter('', '')">Rensa Filter</a>
              </div>
            </div>
            <div class="col">
              <div class="border p-1">
                <h6>Datum</h6>
                <button
                  class="btn btn-sm date-btn"
                  v-for="date in dates"
                  :class="{ 'btn-secondary': filterCritria == date, 'btn-primary': filterCritria != date }"
                  v-on:click="setFilter('fakturaDatum', date)"
                >
                  {{ date }}
                </button>
              </div>
            </div>
          </div>
          <div class="row p-1">Klicka på en rubrikrad för att sortera</div>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-sm">
            <thead>
              <tr>
                <th class="table-primary" v-for="col in columns" v-on:click="sortTable(col.field)">
                  {{ col.headerName }}
                  <i v-if="col.field == sortColumn && ascending" class="bi-arrow-up" />
                  <i v-if="col.field == sortColumn && !ascending" class="bi-arrow-down" />
                </th>
              </tr>
            </thead>
            <tbody>
              <tr scope="col" v-for="row in rows" v-bind:key="row.key">
                <td scope="col" v-for="col in columns" v-bind:key="col?.field">
                  {{ col != null && col.field in row ? row[col.field] : '' }}
                  <button class="btn btn-primary btn-sm" v-if="col.field == 'actions'" v-on:click="download(row['fakturanr'])">Faktura PDF</button>
                  <router-link
                    :to="{ name: 'fakturaspecs', params: { id: row['fakturanr'] } }"
                    class="btn btn-primary btn-sm"
                    v-if="col.field == 'actions'"
                    >Bilaga PDF</router-link
                  >
                  <button class="btn btn-primary btn-sm" v-if="col.field == 'actions'" v-on:click="downloadExcel(row['fakturanr'])">Excel</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="text-center" v-if="!loading && !error && (!allRows || !allRows.length)">
        <strong>
          Du har inga fakturor, Hurray!
          <i class="bi bi-check-circle"></i>
        </strong>
      </div>
      <div class="text-center" v-if="error">
        <strong>
          Error loading the fakturor, please contact the admins
          <i class="bi bi-emoji-dizzy"></i>
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from '@/components/Sidebar.vue'
import Topbar from '@/components/Topbar.vue'
import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import Api from '@/services'
import Spinner from '../components/Spinner.vue'
import Button from '../components/Button.vue'

export default {
  name: 'Home',
  data() {
    return {
      ascending: true,
      sortColumn: 'fakturaDatum',
      filterColumn: '',
      filterCritria: '',
      allRows: [],
      columns: [
        { headerName: 'Fakturanr', field: 'fakturanr', sortable: true },
        { headerName: 'Fakturabelopp', field: 'fakturabelopp', sortable: true },
        { headerName: 'Fakturadatum', field: 'fakturaDatum', sortable: true },
        { headerName: 'Förfallodatum', field: 'forfalloDatum', sortable: true },
        { headerName: 'OCR nr', field: 'ocr', sortable: true },
        { headerName: 'Status', field: 'status', sortable: true },
        { headerName: 'Hantera', field: 'actions', sortable: false }
      ],
      loading: true,
      error: false
    }
  },
  computed: {
    dates() {
      let dates = new Set()
      this.allRows.forEach((row) => {
        dates.add(row.fakturaDatum)
      })
      return Array.from(dates).sort()
    },

    rows() {
      if (this.filterColumn && this.filterCritria) {
        return this.allRows.filter((p) => p[this.filterColumn] == this.filterCritria)
      }
      return this.allRows
    }
  },

  mounted() {
    this.start()
  },
  methods: {
    signout() {
      localStorage.removeItem('loginToken')
      this.$router.push('/login')
    },

    setFilter(column, filter) {
      this.filterColumn = column
      this.filterCritria = filter
    },

    async start() {
      this.loading = true
      this.error = false
      const response = await Api.fakturas()

      if (response == null) {
        this.error = true
      } else {
        this.allRows = response.data
        this.sortTable('fakturaDatum');
      }
      this.loading = false
    },

    sortTable(col) {
      if (this.sortColumn === col) {
        this.ascending = !this.ascending
      } else {
        this.ascending = true
        this.sortColumn = col
      }
      var ascending = this.ascending

      this.rows.sort(function (a, b) {
        if (a[col] > b[col]) {
          return ascending ? 1 : -1
        } else if (a[col] < b[col]) {
          return ascending ? -1 : 1
        }
        return 0
      })
    },
    download(fakturanr) {
      Api.downloadPdf(fakturanr)
    },
    downloadExcel(fakturanr) {
      Api.downloadExcel(fakturanr)
    }
  },
  components: {
    Sidebar,
    Topbar,
    Footer,
    Icon,
    Spinner,
    Button
  }
}
</script>
<style lang="scss">
body {
  font-size: 0.875rem;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.25);
}

.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.btn-sm {
  margin-left: 5px;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
.date-btn {
  cursor: pointer;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 1px;
  margin-bottom: 1px;
}
</style>
